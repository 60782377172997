
//https://ed92-109-37-129-131.ngrok-free.app/payment?checkout_session_id=cs_test_b1txF1jRXR05I11weA0efh4Yb2eI0NtTqBEz4QnwXUd0LRVzroBp3r8NDn
import * as React from "react"
import { navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import styled from "styled-components"

const SuccessPage: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    // Get the checkout session id from the query parameters
    if (typeof window !== 'undefined') {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        console.log("Session id: ", sessionId)
        if (sessionId) {
            // Do stuff
            setTimeout(() => {
              if (typeof window !== 'undefined') {
                window.location.href = '/'; // Redirect to homepage or any other page
              }
            }, 5000); // 5000ms = 5s
        }
    }

  }, [isAuthenticated]);

  return (
    <>
     
        <Wrapper>
        <div>
          <p>Payment complete. You will be redirected in a few seconds.</p>
        </div>
        </Wrapper>
      
    </>
  );
}


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 4rem;
`

export default SuccessPage;
